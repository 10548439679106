.red.dark {
  --md-sys-color-primary: rgb(255 179 176);
  --md-sys-color-surface-tint: rgb(255 179 176);
  --md-sys-color-on-primary: rgb(87 29 30);
  --md-sys-color-primary-container: rgb(115 51 51);
  --md-sys-color-on-primary-container: rgb(255 218 216);
  --md-sys-color-secondary: rgb(230 189 186);
  --md-sys-color-on-secondary: rgb(68 41 40);
  --md-sys-color-secondary-container: rgb(93 63 62);
  --md-sys-color-on-secondary-container: rgb(255 218 216);
  --md-sys-color-tertiary: rgb(227 194 140);
  --md-sys-color-on-tertiary: rgb(65 45 5);
  --md-sys-color-tertiary-container: rgb(90 67 25);
  --md-sys-color-on-tertiary-container: rgb(255 222 170);
  --md-sys-color-error: rgb(255 180 171);
  --md-sys-color-on-error: rgb(105 0 5);
  --md-sys-color-error-container: rgb(147 0 10);
  --md-sys-color-on-error-container: rgb(255 218 214);
  --md-sys-color-background: rgb(26 17 17);
  --md-sys-color-on-background: rgb(240 222 221);
  --md-sys-color-surface: rgb(26 17 17);
  --md-sys-color-on-surface: rgb(240 222 221);
  --md-sys-color-surface-variant: rgb(83 67 66);
  --md-sys-color-on-surface-variant: rgb(215 193 192);
  --md-sys-color-outline: rgb(160 140 139);
  --md-sys-color-outline-variant: rgb(83 67 66);
  --md-sys-color-shadow: rgb(0 0 0);
  --md-sys-color-scrim: rgb(0 0 0);
  --md-sys-color-inverse-surface: rgb(240 222 221);
  --md-sys-color-inverse-on-surface: rgb(56 46 45);
  --md-sys-color-inverse-primary: rgb(144 74 73);
  --md-sys-color-primary-fixed: rgb(255 218 216);
  --md-sys-color-on-primary-fixed: rgb(59 8 11);
  --md-sys-color-primary-fixed-dim: rgb(255 179 176);
  --md-sys-color-on-primary-fixed-variant: rgb(115 51 51);
  --md-sys-color-secondary-fixed: rgb(255 218 216);
  --md-sys-color-on-secondary-fixed: rgb(44 21 20);
  --md-sys-color-secondary-fixed-dim: rgb(230 189 186);
  --md-sys-color-on-secondary-fixed-variant: rgb(93 63 62);
  --md-sys-color-tertiary-fixed: rgb(255 222 170);
  --md-sys-color-on-tertiary-fixed: rgb(39 25 0);
  --md-sys-color-tertiary-fixed-dim: rgb(227 194 140);
  --md-sys-color-on-tertiary-fixed-variant: rgb(90 67 25);
  --md-sys-color-surface-dim: rgb(26 17 17);
  --md-sys-color-surface-bright: rgb(66 55 54);
  --md-sys-color-surface-container-lowest: rgb(20 12 12);
  --md-sys-color-surface-container-low: rgb(35 25 25);
  --md-sys-color-surface-container: rgb(39 29 29);
  --md-sys-color-surface-container-high: rgb(50 40 39);
  --md-sys-color-surface-container-highest: rgb(61 50 50);
  --md-extended-color-custom-color-color: rgb(255 180 172);
  --md-extended-color-custom-color-on-color: rgb(86 30 26);
  --md-extended-color-custom-color-color-container: rgb(115 51 46);
  --md-extended-color-custom-color-on-color-container: rgb(255 218 214);
}
