.phonegraphic {
  --phoneBorderRadius: 36px;
  --phoneScreenBorderRadius: 33px;
  --phoneWidth: 264px;
  --phoneHeight: 533px;
  width: var(--phoneWidth);
  height: var(--phoneHeight);
  padding: 8px;
  border-radius: var(--phoneBorderRadius);
  background: #f6f9fc;
  box-shadow:
    0 50px 100px -20px rgb(50 50 93 / 25%),
    0 30px 60px -30px rgb(0 0 0 / 30%),
    inset 0 -2px 6px 0 rgb(10 37 64 / 35%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;

  > div {
    border-radius: 30px;
  }
}

.dark .phonegraphic {
  background: #2d2d2f;
}

.tabletgraphic {
  --phoneBorderRadius: 24px;
  --phoneScreenBorderRadius: 33px;
  width: 636px;
  height: 915px;
  padding: 8px;
  border-radius: var(--phoneBorderRadius);
  background: #2f2f30;
  box-shadow:
    0 50px 100px -20px rgb(50 50 93 / 25%),
    0 30px 60px -30px rgb(0 0 0 / 30%),
    inset 0 -2px 6px 0 rgb(10 37 64 / 35%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;

  > div {
    border-radius: 16px;
  }
}
