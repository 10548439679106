.gold.light {
  --md-sys-color-primary: rgb(120 90 11);
  --md-sys-color-surface-tint: rgb(120 90 11);
  --md-sys-color-on-primary: rgb(255 255 255);
  --md-sys-color-primary-container: rgb(255 223 159);
  --md-sys-color-on-primary-container: rgb(38 26 0);
  --md-sys-color-secondary: rgb(107 92 63);
  --md-sys-color-on-secondary: rgb(255 255 255);
  --md-sys-color-secondary-container: rgb(245 224 187);
  --md-sys-color-on-secondary-container: rgb(36 26 4);
  --md-sys-color-tertiary: rgb(74 101 70);
  --md-sys-color-on-tertiary: rgb(255 255 255);
  --md-sys-color-tertiary-container: rgb(204 235 196);
  --md-sys-color-on-tertiary-container: rgb(8 32 8);
  --md-sys-color-error: rgb(186 26 26);
  --md-sys-color-on-error: rgb(255 255 255);
  --md-sys-color-error-container: rgb(255 218 214);
  --md-sys-color-on-error-container: rgb(65 0 2);
  --md-sys-color-background: rgb(255 248 242);
  --md-sys-color-on-background: rgb(31 27 19);
  --md-sys-color-surface: rgb(255 248 242);
  --md-sys-color-on-surface: rgb(31 27 19);
  --md-sys-color-surface-variant: rgb(237 225 207);
  --md-sys-color-on-surface-variant: rgb(77 70 57);
  --md-sys-color-outline: rgb(127 118 103);
  --md-sys-color-outline-variant: rgb(208 197 180);
  --md-sys-color-shadow: rgb(0 0 0);
  --md-sys-color-scrim: rgb(0 0 0);
  --md-sys-color-inverse-surface: rgb(53 48 39);
  --md-sys-color-inverse-on-surface: rgb(250 239 226);
  --md-sys-color-inverse-primary: rgb(234 193 108);
  --md-sys-color-primary-fixed: rgb(255 223 159);
  --md-sys-color-on-primary-fixed: rgb(38 26 0);
  --md-sys-color-primary-fixed-dim: rgb(234 193 108);
  --md-sys-color-on-primary-fixed-variant: rgb(92 67 0);
  --md-sys-color-secondary-fixed: rgb(245 224 187);
  --md-sys-color-on-secondary-fixed: rgb(36 26 4);
  --md-sys-color-secondary-fixed-dim: rgb(216 196 160);
  --md-sys-color-on-secondary-fixed-variant: rgb(83 69 42);
  --md-sys-color-tertiary-fixed: rgb(204 235 196);
  --md-sys-color-on-tertiary-fixed: rgb(8 32 8);
  --md-sys-color-tertiary-fixed-dim: rgb(177 207 169);
  --md-sys-color-on-tertiary-fixed-variant: rgb(51 77 48);
  --md-sys-color-surface-dim: rgb(226 217 204);
  --md-sys-color-surface-bright: rgb(255 248 242);
  --md-sys-color-surface-container-lowest: rgb(255 255 255);
  --md-sys-color-surface-container-low: rgb(252 242 229);
  --md-sys-color-surface-container: rgb(247 236 223);
  --md-sys-color-surface-container-high: rgb(241 231 217);
  --md-sys-color-surface-container-highest: rgb(235 225 212);
  --md-extended-color-custom-color-color: rgb(144 74 68);
  --md-extended-color-custom-color-on-color: rgb(255 255 255);
  --md-extended-color-custom-color-color-container: rgb(255 218 214);
  --md-extended-color-custom-color-on-color-container: rgb(59 9 8);
}
