@tailwind base;
@tailwind components;
@tailwind utilities;

@import './content.scss';
@import './phonegraphic.scss';

@import './palettes/purple/light.css';
@import './palettes/purple/dark.css';

@import './palettes/gold/light.css';
@import './palettes/gold/dark.css';

@import './palettes/green/light.css';
@import './palettes/green/dark.css';

@import './palettes/pink/light.css';
@import './palettes/pink/dark.css';

@import './palettes/blue/light.css';
@import './palettes/blue/dark.css';

@import './palettes/red/light.css';
@import './palettes/red/dark.css';

@layer components {
  .button-base {
    @apply font-normal;
    @apply leading-36px;
    @apply text-[14px];
    @apply inline-flex;
    @apply items-center;
    @apply rounded-md;
    @apply focus:outline-none;
    @apply transition-colors;
    @apply transition-shadow;
    @apply duration-300;
  }

  .bringmos-card-wrapper {
    @apply rounded-lg border border-gray-500/20 bg-light-border-gradient dark:border-none dark:bg-light-border-gradient;
  }

  .bringmos-checkbox {
    @apply form-checkbox h-5 w-5 rounded-md shadow-sm;
    @apply text-primary dark:text-primary-container;
    @apply focus:border-primary-container focus:ring-1 focus:ring-offset-0 focus:ring-on-primary-container focus:ring-opacity-50;
  }

  .bringmos-radio {
    @apply form-radio h-5 w-5 rounded-md shadow-sm;
    @apply text-primary dark:text-primary-container;
    @apply focus:border-primary-container focus:ring-1 focus:ring-offset-0 focus:ring-on-primary-container focus:ring-opacity-50;
  }
}

h2 {
  @apply text-4xl font-semibold;
}

h3 {
  @apply font-semibold;
}

.start-grid {
  --header-height: 64px;
  --content-columns: 12;
  --gutter-columns: 4;
  --gutter-column-width: 64px;
  --content-column-width: 64px;
  position: absolute;
  top: -100px;
  left: 0;
  right: 0;
  height: 100%;
  -webkit-transform: skewY(-12deg);
  transform: skewY(-12deg);
  align-content: end;
  display: grid;
  grid-template-rows: repeat(auto-fill, var(--header-height));
  grid-template-columns:
    [viewport-start] 1fr [left-gutter-start]
    repeat(var(--gutter-columns), var(--gutter-column-width))
    [left-gutter-end content-start]
    repeat(var(--content-columns), var(--content-column-width))
    [content-end right-gutter-start]
    repeat(var(--gutter-columns), var(--gutter-column-width))
    [right-gutter-end] 1fr [viewport-end];

  .bg-item {
    box-sizing: border-box;
    // background-color: #cd5c5c;
    grid-row: span 4/-1;
    grid-column: span 10 / viewport-end;
  }

  .bg-item-2 {
    // background: #f7acac;
    box-sizing: border-box;
    grid-column: span 5/-1;
    grid-row: span 3/-2;
    transform: translateY(100%);
    z-index: -1;
  }

  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fafafa;
  border-radius: 8px;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #fafafa;
}
::-webkit-scrollbar-thumb {
  background-color: #737c8850;
  border-radius: 8px;
  cursor: pointer;
}

.dark {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #00000020;
    border-radius: 8px;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #00000020;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #737c8850;
    border-radius: 8px;
    cursor: pointer;
  }
}
