.purple.dark,
.dark {
  --md-sys-color-primary: rgb(211 188 253);
  --md-sys-color-surface-tint: rgb(211 188 253);
  --md-sys-color-on-primary: rgb(56 38 92);
  --md-sys-color-primary-container: rgb(79 61 116);
  --md-sys-color-on-primary-container: rgb(235 221 255);
  --md-sys-color-secondary: rgb(205 194 219);
  --md-sys-color-on-secondary: rgb(52 45 64);
  --md-sys-color-secondary-container: rgb(75 67 88);
  --md-sys-color-on-secondary-container: rgb(233 222 248);
  --md-sys-color-tertiary: rgb(240 183 197);
  --md-sys-color-on-tertiary: rgb(74 37 48);
  --md-sys-color-tertiary-container: rgb(100 59 70);
  --md-sys-color-on-tertiary-container: rgb(255 217 225);
  --md-sys-color-error: rgb(255 180 171);
  --md-sys-color-on-error: rgb(105 0 5);
  --md-sys-color-error-container: rgb(147 0 10);
  --md-sys-color-on-error-container: rgb(255 218 214);
  --md-sys-color-background: rgb(21 18 24);
  --md-sys-color-on-background: rgb(231 224 232);
  --md-sys-color-surface: rgb(21 18 24);
  --md-sys-color-on-surface: rgb(231 224 232);
  --md-sys-color-surface-variant: rgb(73 69 78);
  --md-sys-color-on-surface-variant: rgb(203 196 207);
  --md-sys-color-outline: rgb(148 143 153);
  --md-sys-color-outline-variant: rgb(73 69 78);
  --md-sys-color-shadow: rgb(0 0 0);
  --md-sys-color-scrim: rgb(0 0 0);
  --md-sys-color-inverse-surface: rgb(231 224 232);
  --md-sys-color-inverse-on-surface: rgb(50 47 53);
  --md-sys-color-inverse-primary: rgb(104 84 142);
  --md-sys-color-primary-fixed: rgb(235 221 255);
  --md-sys-color-on-primary-fixed: rgb(35 15 70);
  --md-sys-color-primary-fixed-dim: rgb(211 188 253);
  --md-sys-color-on-primary-fixed-variant: rgb(79 61 116);
  --md-sys-color-secondary-fixed: rgb(233 222 248);
  --md-sys-color-on-secondary-fixed: rgb(31 24 43);
  --md-sys-color-secondary-fixed-dim: rgb(205 194 219);
  --md-sys-color-on-secondary-fixed-variant: rgb(75 67 88);
  --md-sys-color-tertiary-fixed: rgb(255 217 225);
  --md-sys-color-on-tertiary-fixed: rgb(49 16 27);
  --md-sys-color-tertiary-fixed-dim: rgb(240 183 197);
  --md-sys-color-on-tertiary-fixed-variant: rgb(100 59 70);
  --md-sys-color-surface-dim: rgb(21 18 24);
  --md-sys-color-surface-bright: rgb(59 56 62);
  --md-sys-color-surface-container-lowest: rgb(15 13 19);
  --md-sys-color-surface-container-low: rgb(29 27 32);
  --md-sys-color-surface-container: rgb(33 31 36);
  --md-sys-color-surface-container-high: rgb(44 41 47);
  --md-sys-color-surface-container-highest: rgb(54 52 58);
  --md-extended-color-custom-color-color: rgb(164 201 254);
  --md-extended-color-custom-color-on-color: rgb(0 49 93);
  --md-extended-color-custom-color-color-container: rgb(32 72 118);
  --md-extended-color-custom-color-on-color-container: rgb(211 227 255);
}
