.green.dark {
  --md-sys-color-primary: rgb(153 212 162);
  --md-sys-color-surface-tint: rgb(153 212 162);
  --md-sys-color-on-primary: rgb(0 57 24);
  --md-sys-color-primary-container: rgb(24 81 43);
  --md-sys-color-on-primary-container: rgb(180 241 189);
  --md-sys-color-secondary: rgb(183 204 183);
  --md-sys-color-on-secondary: rgb(35 52 38);
  --md-sys-color-secondary-container: rgb(57 75 59);
  --md-sys-color-on-secondary-container: rgb(211 232 210);
  --md-sys-color-tertiary: rgb(162 206 216);
  --md-sys-color-on-tertiary: rgb(1 54 62);
  --md-sys-color-tertiary-container: rgb(32 77 85);
  --md-sys-color-on-tertiary-container: rgb(189 234 244);
  --md-sys-color-error: rgb(255 180 171);
  --md-sys-color-on-error: rgb(105 0 5);
  --md-sys-color-error-container: rgb(147 0 10);
  --md-sys-color-on-error-container: rgb(255 218 214);
  --md-sys-color-background: rgb(16 21 16);
  --md-sys-color-on-background: rgb(223 228 220);
  --md-sys-color-surface: rgb(16 21 16);
  --md-sys-color-on-surface: rgb(223 228 220);
  --md-sys-color-surface-variant: rgb(65 73 65);
  --md-sys-color-on-surface-variant: rgb(193 201 190);
  --md-sys-color-outline: rgb(139 147 137);
  --md-sys-color-outline-variant: rgb(65 73 65);
  --md-sys-color-shadow: rgb(0 0 0);
  --md-sys-color-scrim: rgb(0 0 0);
  --md-sys-color-inverse-surface: rgb(223 228 220);
  --md-sys-color-inverse-on-surface: rgb(45 50 44);
  --md-sys-color-inverse-primary: rgb(50 105 65);
  --md-sys-color-primary-fixed: rgb(180 241 189);
  --md-sys-color-on-primary-fixed: rgb(0 33 11);
  --md-sys-color-primary-fixed-dim: rgb(153 212 162);
  --md-sys-color-on-primary-fixed-variant: rgb(24 81 43);
  --md-sys-color-secondary-fixed: rgb(211 232 210);
  --md-sys-color-on-secondary-fixed: rgb(14 31 18);
  --md-sys-color-secondary-fixed-dim: rgb(183 204 183);
  --md-sys-color-on-secondary-fixed-variant: rgb(57 75 59);
  --md-sys-color-tertiary-fixed: rgb(189 234 244);
  --md-sys-color-on-tertiary-fixed: rgb(0 31 37);
  --md-sys-color-tertiary-fixed-dim: rgb(162 206 216);
  --md-sys-color-on-tertiary-fixed-variant: rgb(32 77 85);
  --md-sys-color-surface-dim: rgb(16 21 16);
  --md-sys-color-surface-bright: rgb(53 58 53);
  --md-sys-color-surface-container-lowest: rgb(11 15 11);
  --md-sys-color-surface-container-low: rgb(24 29 24);
  --md-sys-color-surface-container: rgb(28 33 28);
  --md-sys-color-surface-container-high: rgb(38 43 38);
  --md-sys-color-surface-container-highest: rgb(49 54 49);
  --md-extended-color-custom-color-color: rgb(255 180 172);
  --md-extended-color-custom-color-on-color: rgb(86 30 26);
  --md-extended-color-custom-color-color-container: rgb(115 51 46);
  --md-extended-color-custom-color-on-color-container: rgb(255 218 214);
}
