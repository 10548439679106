.pink.light {
  --md-sys-color-primary: rgb(133 75 113);
  --md-sys-color-surface-tint: rgb(133 75 113);
  --md-sys-color-on-primary: rgb(255 255 255);
  --md-sys-color-primary-container: rgb(255 216 237);
  --md-sys-color-on-primary-container: rgb(54 7 43);
  --md-sys-color-secondary: rgb(112 87 101);
  --md-sys-color-on-secondary: rgb(255 255 255);
  --md-sys-color-secondary-container: rgb(251 217 235);
  --md-sys-color-on-secondary-container: rgb(40 22 33);
  --md-sys-color-tertiary: rgb(128 84 62);
  --md-sys-color-on-tertiary: rgb(255 255 255);
  --md-sys-color-tertiary-container: rgb(255 219 204);
  --md-sys-color-on-tertiary-container: rgb(49 19 4);
  --md-sys-color-error: rgb(186 26 26);
  --md-sys-color-on-error: rgb(255 255 255);
  --md-sys-color-error-container: rgb(255 218 214);
  --md-sys-color-on-error-container: rgb(65 0 2);
  --md-sys-color-background: rgb(255 248 249);
  --md-sys-color-on-background: rgb(33 26 29);
  --md-sys-color-surface: rgb(255 248 249);
  --md-sys-color-on-surface: rgb(33 26 29);
  --md-sys-color-surface-variant: rgb(240 222 229);
  --md-sys-color-on-surface-variant: rgb(79 68 74);
  --md-sys-color-outline: rgb(129 115 122);
  --md-sys-color-outline-variant: rgb(211 194 201);
  --md-sys-color-shadow: rgb(0 0 0);
  --md-sys-color-scrim: rgb(0 0 0);
  --md-sys-color-inverse-surface: rgb(54 46 50);
  --md-sys-color-inverse-on-surface: rgb(251 237 242);
  --md-sys-color-inverse-primary: rgb(248 177 220);
  --md-sys-color-primary-fixed: rgb(255 216 237);
  --md-sys-color-on-primary-fixed: rgb(54 7 43);
  --md-sys-color-primary-fixed-dim: rgb(248 177 220);
  --md-sys-color-on-primary-fixed-variant: rgb(106 52 88);
  --md-sys-color-secondary-fixed: rgb(251 217 235);
  --md-sys-color-on-secondary-fixed: rgb(40 22 33);
  --md-sys-color-secondary-fixed-dim: rgb(222 190 206);
  --md-sys-color-on-secondary-fixed-variant: rgb(87 64 77);
  --md-sys-color-tertiary-fixed: rgb(255 219 204);
  --md-sys-color-on-tertiary-fixed: rgb(49 19 4);
  --md-sys-color-tertiary-fixed-dim: rgb(244 186 159);
  --md-sys-color-on-tertiary-fixed-variant: rgb(101 61 41);
  --md-sys-color-surface-dim: rgb(228 214 220);
  --md-sys-color-surface-bright: rgb(255 248 249);
  --md-sys-color-surface-container-lowest: rgb(255 255 255);
  --md-sys-color-surface-container-low: rgb(254 240 245);
  --md-sys-color-surface-container: rgb(248 234 239);
  --md-sys-color-surface-container-high: rgb(243 229 234);
  --md-sys-color-surface-container-highest: rgb(237 223 228);
  --md-extended-color-custom-color-color: rgb(144 74 68);
  --md-extended-color-custom-color-on-color: rgb(255 255 255);
  --md-extended-color-custom-color-color-container: rgb(255 218 214);
  --md-extended-color-custom-color-on-color-container: rgb(59 9 8);
}
