.content {
  font-weight: 300;
}

.content time {
  color: #9b9b9b;
}

.content p {
  line-height: 1.5rem;
  margin: 1rem 0 0 0;
}

.content a {
  color: #cd5c5c;
  text-decoration: none;
}

.content a {
  color: #cd5c5c;
  text-decoration: none;
}

.dark .content a {
  color: #cd5c5c;
}

.dark .content a:hover {
  color: #cd5c5c;
}

.content h1 {
  margin: 2.5rem 0 0 0;
  font-size: 1.5rem;
  font-weight: 400;
}

.content h2 {
  font-size: 1.3rem;
}

.content h3 {
  font-size: 1.2rem;
}

.content h4,
.content h5 {
  font-size: 1.1rem;
}

.content h2,
.content h3,
.content h4,
.content h5 {
  margin: 3.5rem 0 0 0;
  line-height: 1.25em;
  font-weight: 400;
}

.content h3 {
  margin: 2.5rem 0 0 0;
}

.content h4,
.content h5 {
  margin: 2rem 0 0 0;
}

.content pre {
  display: block;
  background-color: rgba(27, 31, 35, 0.05);
  line-height: 1.25rem;
  padding: 1rem;
  overflow: auto;
  margin: 1.75rem 0 0 0;
}

.content pre code {
  background-color: transparent;
  font-size: 100%;
  padding: 0;
}

.content code {
  font-family: 'Ubuntu Mono', monospace;
  font-size: 85%;
  padding: 0.2em 0.4em;
  margin: 0;
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
}

.content blockquote {
  margin: 0 1rem;
}

.content blockquote::before {
  position: absolute;
  content: '\201C';
  font-size: 6em;
  font-family: roboto, serif;
  line-height: 1.5rem;
  margin-top: 0.1em;
  margin-left: -0.2em;
  z-index: -1;
  color: #ededed;
}

.content table {
  max-width: 100%;
  border-spacing: 0;
  margin-top: 1.5rem;
}

.content table thead {
  background: #f7f7f7;
}

.content table th {
  font-weight: 500;
}

.content table th,
.content table td {
  padding: 0.5em 1em;
  border: 1px double #eee;
}

.content ol,
.content ul {
  padding: 0 0 0 1.5rem;
  margin: 1.5rem 0 0 0;
}

.content ol li,
.content ul li {
  line-height: 1.5rem;
  list-style: none;
  margin: 10px 0;
}

.content li ol,
.content li ul {
  margin: 0;
}

.content ul li::before,
.content ol li::before {
  content: '\2022';
  color: #cd5c5c;
  font-weight: bold;
  display: inline-block;
  width: 20px;
  margin-left: -20px;
  font-style: normal;
}

.content abbr[title] {
  text-decoration: underline double;
}

.content kbd {
  font-family: 'Ubuntu Mono', monospace;
}

.content hr {
  margin: 1.5rem 0;
}

.content img {
  border-radius: 8px;
  margin: 1rem 0;
}

.content .giphy-wrapper {
  margin-top: 2rem;
  width: 100%;
  height: 0;
  padding-bottom: 56%;
  position: relative;

  iframe {
    position: absolute;
  }
}

.content .team-members {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem -0.5rem 0 -0.5rem;

  img {
    width: 100px;
    margin: 0.5rem;
  }
}

@media (min-width: 769px) {
  .content h2,
  .content h3,
  .content h4,
  .content h5 {
    position: relative;
  }

  .content h2::before {
    display: block;
  }
}
