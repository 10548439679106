.red.light {
  --md-sys-color-primary: rgb(144 74 73);
  --md-sys-color-surface-tint: rgb(144 74 73);
  --md-sys-color-on-primary: rgb(255 255 255);
  --md-sys-color-primary-container: rgb(255 218 216);
  --md-sys-color-on-primary-container: rgb(59 8 11);
  --md-sys-color-secondary: rgb(119 86 85);
  --md-sys-color-on-secondary: rgb(255 255 255);
  --md-sys-color-secondary-container: rgb(255 218 216);
  --md-sys-color-on-secondary-container: rgb(44 21 20);
  --md-sys-color-tertiary: rgb(116 90 46);
  --md-sys-color-on-tertiary: rgb(255 255 255);
  --md-sys-color-tertiary-container: rgb(255 222 170);
  --md-sys-color-on-tertiary-container: rgb(39 25 0);
  --md-sys-color-error: rgb(186 26 26);
  --md-sys-color-on-error: rgb(255 255 255);
  --md-sys-color-error-container: rgb(255 218 214);
  --md-sys-color-on-error-container: rgb(65 0 2);
  --md-sys-color-background: rgb(255 248 247);
  --md-sys-color-on-background: rgb(35 25 25);
  --md-sys-color-surface: rgb(255 248 247);
  --md-sys-color-on-surface: rgb(35 25 25);
  --md-sys-color-surface-variant: rgb(244 221 220);
  --md-sys-color-on-surface-variant: rgb(83 67 66);
  --md-sys-color-outline: rgb(133 115 114);
  --md-sys-color-outline-variant: rgb(215 193 192);
  --md-sys-color-shadow: rgb(0 0 0);
  --md-sys-color-scrim: rgb(0 0 0);
  --md-sys-color-inverse-surface: rgb(56 46 45);
  --md-sys-color-inverse-on-surface: rgb(255 237 235);
  --md-sys-color-inverse-primary: rgb(255 179 176);
  --md-sys-color-primary-fixed: rgb(255 218 216);
  --md-sys-color-on-primary-fixed: rgb(59 8 11);
  --md-sys-color-primary-fixed-dim: rgb(255 179 176);
  --md-sys-color-on-primary-fixed-variant: rgb(115 51 51);
  --md-sys-color-secondary-fixed: rgb(255 218 216);
  --md-sys-color-on-secondary-fixed: rgb(44 21 20);
  --md-sys-color-secondary-fixed-dim: rgb(230 189 186);
  --md-sys-color-on-secondary-fixed-variant: rgb(93 63 62);
  --md-sys-color-tertiary-fixed: rgb(255 222 170);
  --md-sys-color-on-tertiary-fixed: rgb(39 25 0);
  --md-sys-color-tertiary-fixed-dim: rgb(227 194 140);
  --md-sys-color-on-tertiary-fixed-variant: rgb(90 67 25);
  --md-sys-color-surface-dim: rgb(232 214 213);
  --md-sys-color-surface-bright: rgb(255 248 247);
  --md-sys-color-surface-container-lowest: rgb(255 255 255);
  --md-sys-color-surface-container-low: rgb(255 240 239);
  --md-sys-color-surface-container: rgb(252 234 232);
  --md-sys-color-surface-container-high: rgb(246 228 227);
  --md-sys-color-surface-container-highest: rgb(240 222 221);
  --md-extended-color-custom-color-color: rgb(144 74 68);
  --md-extended-color-custom-color-on-color: rgb(255 255 255);
  --md-extended-color-custom-color-color-container: rgb(255 218 214);
  --md-extended-color-custom-color-on-color-container: rgb(59 9 8);
}
