.green.light {
  --md-sys-color-primary: rgb(50 105 65);
  --md-sys-color-surface-tint: rgb(50 105 65);
  --md-sys-color-on-primary: rgb(255 255 255);
  --md-sys-color-primary-container: rgb(180 241 189);
  --md-sys-color-on-primary-container: rgb(0 33 11);
  --md-sys-color-secondary: rgb(80 99 82);
  --md-sys-color-on-secondary: rgb(255 255 255);
  --md-sys-color-secondary-container: rgb(211 232 210);
  --md-sys-color-on-secondary-container: rgb(14 31 18);
  --md-sys-color-tertiary: rgb(58 101 110);
  --md-sys-color-on-tertiary: rgb(255 255 255);
  --md-sys-color-tertiary-container: rgb(189 234 244);
  --md-sys-color-on-tertiary-container: rgb(0 31 37);
  --md-sys-color-error: rgb(186 26 26);
  --md-sys-color-on-error: rgb(255 255 255);
  --md-sys-color-error-container: rgb(255 218 214);
  --md-sys-color-on-error-container: rgb(65 0 2);
  --md-sys-color-background: rgb(246 251 242);
  --md-sys-color-on-background: rgb(24 29 24);
  --md-sys-color-surface: rgb(246 251 242);
  --md-sys-color-on-surface: rgb(24 29 24);
  --md-sys-color-surface-variant: rgb(221 229 218);
  --md-sys-color-on-surface-variant: rgb(65 73 65);
  --md-sys-color-outline: rgb(113 121 112);
  --md-sys-color-outline-variant: rgb(193 201 190);
  --md-sys-color-shadow: rgb(0 0 0);
  --md-sys-color-scrim: rgb(0 0 0);
  --md-sys-color-inverse-surface: rgb(45 50 44);
  --md-sys-color-inverse-on-surface: rgb(238 242 234);
  --md-sys-color-inverse-primary: rgb(153 212 162);
  --md-sys-color-primary-fixed: rgb(180 241 189);
  --md-sys-color-on-primary-fixed: rgb(0 33 11);
  --md-sys-color-primary-fixed-dim: rgb(153 212 162);
  --md-sys-color-on-primary-fixed-variant: rgb(24 81 43);
  --md-sys-color-secondary-fixed: rgb(211 232 210);
  --md-sys-color-on-secondary-fixed: rgb(14 31 18);
  --md-sys-color-secondary-fixed-dim: rgb(183 204 183);
  --md-sys-color-on-secondary-fixed-variant: rgb(57 75 59);
  --md-sys-color-tertiary-fixed: rgb(189 234 244);
  --md-sys-color-on-tertiary-fixed: rgb(0 31 37);
  --md-sys-color-tertiary-fixed-dim: rgb(162 206 216);
  --md-sys-color-on-tertiary-fixed-variant: rgb(32 77 85);
  --md-sys-color-surface-dim: rgb(215 219 211);
  --md-sys-color-surface-bright: rgb(246 251 242);
  --md-sys-color-surface-container-lowest: rgb(255 255 255);
  --md-sys-color-surface-container-low: rgb(241 245 237);
  --md-sys-color-surface-container: rgb(235 239 231);
  --md-sys-color-surface-container-high: rgb(229 234 225);
  --md-sys-color-surface-container-highest: rgb(223 228 220);
  --md-extended-color-custom-color-color: rgb(144 74 68);
  --md-extended-color-custom-color-on-color: rgb(255 255 255);
  --md-extended-color-custom-color-color-container: rgb(255 218 214);
  --md-extended-color-custom-color-on-color-container: rgb(59 9 8);
}
