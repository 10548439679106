.blue.dark {
  --md-sys-color-primary: rgb(180 197 255);
  --md-sys-color-surface-tint: rgb(180 197 255);
  --md-sys-color-on-primary: rgb(27 45 97);
  --md-sys-color-primary-container: rgb(51 68 120);
  --md-sys-color-on-primary-container: rgb(219 225 255);
  --md-sys-color-secondary: rgb(193 197 221);
  --md-sys-color-on-secondary: rgb(43 48 66);
  --md-sys-color-secondary-container: rgb(65 70 89);
  --md-sys-color-on-secondary-container: rgb(221 225 249);
  --md-sys-color-tertiary: rgb(226 187 219);
  --md-sys-color-on-tertiary: rgb(66 39 64);
  --md-sys-color-tertiary-container: rgb(91 61 88);
  --md-sys-color-on-tertiary-container: rgb(255 214 247);
  --md-sys-color-error: rgb(255 180 171);
  --md-sys-color-on-error: rgb(105 0 5);
  --md-sys-color-error-container: rgb(147 0 10);
  --md-sys-color-on-error-container: rgb(255 218 214);
  --md-sys-color-background: rgb(18 19 24);
  --md-sys-color-on-background: rgb(227 226 233);
  --md-sys-color-surface: rgb(18 19 24);
  --md-sys-color-on-surface: rgb(227 226 233);
  --md-sys-color-surface-variant: rgb(69 70 79);
  --md-sys-color-on-surface-variant: rgb(198 198 208);
  --md-sys-color-outline: rgb(143 144 154);
  --md-sys-color-outline-variant: rgb(69 70 79);
  --md-sys-color-shadow: rgb(0 0 0);
  --md-sys-color-scrim: rgb(0 0 0);
  --md-sys-color-inverse-surface: rgb(227 226 233);
  --md-sys-color-inverse-on-surface: rgb(47 48 54);
  --md-sys-color-inverse-primary: rgb(75 92 146);
  --md-sys-color-primary-fixed: rgb(219 225 255);
  --md-sys-color-on-primary-fixed: rgb(1 23 75);
  --md-sys-color-primary-fixed-dim: rgb(180 197 255);
  --md-sys-color-on-primary-fixed-variant: rgb(51 68 120);
  --md-sys-color-secondary-fixed: rgb(221 225 249);
  --md-sys-color-on-secondary-fixed: rgb(22 27 44);
  --md-sys-color-secondary-fixed-dim: rgb(193 197 221);
  --md-sys-color-on-secondary-fixed-variant: rgb(65 70 89);
  --md-sys-color-tertiary-fixed: rgb(255 214 247);
  --md-sys-color-on-tertiary-fixed: rgb(43 18 42);
  --md-sys-color-tertiary-fixed-dim: rgb(226 187 219);
  --md-sys-color-on-tertiary-fixed-variant: rgb(91 61 88);
  --md-sys-color-surface-dim: rgb(18 19 24);
  --md-sys-color-surface-bright: rgb(56 57 63);
  --md-sys-color-surface-container-lowest: rgb(13 14 19);
  --md-sys-color-surface-container-low: rgb(26 27 33);
  --md-sys-color-surface-container: rgb(30 31 37);
  --md-sys-color-surface-container-high: rgb(41 42 47);
  --md-sys-color-surface-container-highest: rgb(52 52 58);
  --md-extended-color-custom-color-color: rgb(255 180 172);
  --md-extended-color-custom-color-on-color: rgb(86 30 26);
  --md-extended-color-custom-color-color-container: rgb(115 51 46);
  --md-extended-color-custom-color-on-color-container: rgb(255 218 214);
}
